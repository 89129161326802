<template>
    <div class="commodityEnd">
<!--        <div class="top">-->
<!--           <el-button @click="show=1">框架眼镜商品交付</el-button>-->
<!--           <el-button @click="show=2">角膜接触镜商品交付</el-button>-->
<!--        </div>-->
        <div v-if="types!='rgp' && this.shopdata" class="bg dys" ref="print">
            <p class="bg-name">框架眼镜商品交付</p>
            <ul class="gk-xx">
                <li>顾客姓名</li>
                <li>{{shopdata.CustomerFull.Customer.CustomerName}}</li>
                <li>手机号码</li>
                <li>{{shopdata.CustomerFull.Customer.CustomerTel}}</li>
                <li>订单编号</li>
                <li>{{shopdata.COrderId.substring(0,8).toUpperCase()}}</li>
            </ul>

            <p style="padding: 6px 0;border-top: 1px solid #5a5959;">订单信息</p>
            <ul class="gk-xx">
                <li>销售门店</li>
                <li style="display: block;line-height: 30px;">{{shopdata.Shop.ShopName}}</li>
                <li>地址</li>
                <li style="display: block;line-height: 30px;">{{shopdata.Shop.ShopAddress}}</li>
                <li>电话</li>
                <li>{{shopdata.Shop.ShopTel}}</li>
            </ul>
            <ul class="cfsj">
                <li>处方数据</li>
                <li v-for="(item, idx) in dqcfarr" @click="xzcf(item)">处方{{idx+1}}</li>
            </ul>

            <p style="padding: 6px 0;border-top: 1px solid #5a5959;">商品</p>
            <ul class="sp" v-for="item in shopdata.ListCustomerOrderDetail">
                <li>{{item.ProductRelation.ProductDetail.AllProduct.ProductName}}</li>
                <li>单价</li>
                <li>{{item.ProductRelation.ProductDetail.AllProduct.ProductOfficalPrice}}</li>
                <li>数量</li>
                <li>1</li>
                <li>总价</li>
                <li>{{item.ProductRelation.ProductDetail.AllProduct.ProductOfficalPrice}}</li>
            </ul>
            <p style="padding: 6px 0;border-top: 1px solid #5a5959;">总额：{{zj}}</p>

            <ul class="lc">
                <li>订单加工流程</li>
                <li>时间</li>
            </ul>
            <ul class="lc">
                <li>镜框厂家接单</li>
                <li></li>
            </ul>
            <ul class="lc">
                <li>镜片出厂</li>
                <li></li>
            </ul>
            <ul class="lc">
                <li>定配加工</li>
                <li></li>
            </ul>
            <ul class="lc">
                <li>质检</li>
                <li></li>
            </ul>
            <ul class="lc">
                <li>眼镜发货</li>
                <li></li>
            </ul>
          <div v-if="this.shopdata.CentralizedC">
            <p style="padding: 6px 0;border-top: 1px solid #5a5959;">3D适配还原报告</p>
            <ul class="d3bg">
              <li>项次</li>
              <li>标准值</li>
              <li>测量值</li>
              <li></li>
            </ul>
            <ul class="d3bg">
              <li>前倾角</li>
              <li>5°-8°</li>
              <li>{{this.shopdata.CentralizedC.CentralizedData.Panto}}°</li>
              <li><i v-if="this.shopdata.CentralizedC.CentralizedData.Panto > 5 || this.shopdata.CentralizedC.CentralizedData.Panto < 8" class="el-icon-check"></i></li>
            </ul>
            <ul class="d3bg">
              <li>镜眼距</li>
              <li>12mm</li>
              <li>{{this.shopdata.CentralizedC.CentralizedData.BVD}}mm</li>
              <li><i v-if="this.shopdata.CentralizedC.CentralizedData.BVD == 12" class="el-icon-check"></i></li>
            </ul>
            <ul class="d3bg" v-if="0">
              <li>水平位差</li>
              <li>0mm</li>
              <li>121mm</li>
              <li><i class="el-icon-check"></i></li>
            </ul>
            <ul class="d3bg">
              <li></li>
              <li></li>
              <li>
                <p>右眼</p>
                <p>左眼</p>
              </li>
              <li></li>
            </ul>
            <ul class="d3bg">
              <li>上光区</li>
              <li>18.53cm</li>
              <li>
                <p></p>
                <p></p>
              </li>
              <li><i v-if="0" class="el-icon-check"></i></li>
            </ul>
            <ul class="d3bg">
              <li>下光区</li>
              <li>22.53cm</li>
              <li>
                <p></p>
                <p></p>
              </li>
              <li><i v-if="0" class="el-icon-check"></i></li>
            </ul>
            <ul class="d3bg">
              <li>镜框宽</li>
              <li>{{this.shopdata.CentralizedC.CentralizedData.FrameWidth}}mm</li>
              <li>镜框高</li>
              <li>{{this.shopdata.CentralizedC.CentralizedData.FrameHeight}}mm</li>
            </ul>
            <ul class="d3bg">
              <li>鼻梁间距</li>
              <li>{{this.shopdata.CentralizedC.CentralizedData.BlankDiameter}}mm</li>
              <li></li>
              <li></li>
            </ul>
            <ul class="d3bg">
              <li>右眼瞳距</li>
              <li>{{this.shopdata.CentralizedC.CentralizedData.ODPD}}mm</li>
              <li>左眼瞳距</li>
              <li>{{this.shopdata.CentralizedC.CentralizedData.OSPD}}mm</li>
            </ul>
          </div>


<!--            <p style="padding: 6px 0;border-top: 1px solid #5a5959;">适配合格</p>-->
            <div class="zysx">注意事项：1、眼镜不能随便放，要放到指定位置，例如：眼镜盒里面。2、放置眼镜的时候，要把镜片朝上，否则镜面可能会受损。3、不要用类似于吹风机的东西给眼镜加热或者把眼镜放在高温处，否则可能会导致眼镜断裂。4、眼镜被弄脏时，要用眼镜清洁剂或者是中性的洗剂来擦洗，建议定期去眼镜店进行专业清洗。</div>
            <ul class="jfsj">
                <li>交付时间：{{jftime}}</li>
                <li @click="$alert('签名成功', '提示')">顾客签名</li>
                <li @click="$alert('获取电子并签名成功', '提示')">视光师签名</li>
                <li>复查时间：{{this.shopdata.ChooseFull.Choose.ChooseRecommTime}}</li>
                <li @click="tjs">提交</li>
            </ul>
    </div>

      <div v-if="types=='rgp' && this.shopdata" class="bg dys" ref="print2">
            <p class="bg-name">角膜接触镜商品交付</p>
            <ul class="gk-xx">
              <li>顾客姓名</li>
              <li>{{shopdata.CustomerFull.Customer.CustomerName}}</li>
              <li>手机号码</li>
              <li>{{shopdata.CustomerFull.Customer.CustomerTel}}</li>
              <li>订单编号</li>
              <li>{{shopdata.COrderId.substring(0,8).toUpperCase()}}</li>
            </ul>

            <p style="padding: 6px 0;border-top: 1px solid #5a5959;">订单信息</p>
            <ul class="gk-xx">
              <li>销售门店</li>
              <li style="display: block;line-height: 30px;">{{shopdata.Shop.ShopName}}</li>
              <li>地址</li>
              <li style="display: block;line-height: 30px;">{{shopdata.Shop.ShopAddress}}</li>
              <li>电话</li>
              <li>{{shopdata.Shop.ShopTel}}</li>
            </ul>
            <ul class="cfsj">
              <li>处方数据</li>
              <li v-for="(item, idx) in dqcfarr" @click="xzcf(item)">处方{{idx+1}}</li>
            </ul>

            <p style="padding: 6px 0;border-top: 1px solid #5a5959;">商品</p>
        <ul class="sp" v-for="item in shopdata.ListCustomerOrderDetail">
          <li>{{item.ProductRelation.ProductDetail.AllProduct.ProductName}}</li>
          <li>单价</li>
          <li>{{item.ProductRelation.ProductDetail.AllProduct.ProductOfficalPrice}}</li>
          <li>数量</li>
          <li>1</li>
          <li>总价</li>
          <li>{{item.ProductRelation.ProductDetail.AllProduct.ProductOfficalPrice}}</li>
        </ul>
            <p style="padding: 6px 0;border-top: 1px solid #5a5959;">总额：{{zj}}</p>

            <ul class="lc">
                <li>订单加工流程</li>
                <li></li>
            </ul>
            <ul class="lc">
                <li>镜框厂家接单</li>
                <li></li>
            </ul>
            <ul class="lc">
                <li>镜片出厂</li>
                <li></li>
            </ul>
            <ul class="lc">
                <li>定配加工</li>
                <li></li>
            </ul>
            <ul class="lc">
                <li>质检</li>
                <li></li>
            </ul>
            <ul class="lc">
                <li>眼镜发货</li>
                <li></li>
            </ul>

            <div class="zysx">注意事项：1、眼镜不能随便放，要放到指定位置，例如：眼镜盒里面。2、放置眼镜的时候，要把镜片朝上，否则镜面可能会受损。3、不要用类似于吹风机的东西给眼镜加热或者把眼镜放在高温处，否则可能会导致眼镜断裂。4、眼镜被弄脏时，要用眼镜清洁剂或者是中性的洗剂来擦洗，建议定期去眼镜店进行专业清洗。</div>
            <ul class="jfsj">
                <li>交付时间：{{jftime}}</li>
                <li @click="$alert('签名成功', '提示')">顾客签名</li>
                <li @click="$alert('获取电子并签名成功', '提示')">视光师签名</li>
                <li>复查时间：{{this.shopdata.ChooseFull.Choose.ChooseRecommTime}}</li>
                <li @click="tjs">提交</li>
            </ul>
        </div>

      <el-dialog
          title="处方数据"
          :visible.sync="showcf"
          width="50%"
          >
        <div>
          <p v-if="dqcf.type=='OS'">左眼({{dqcf.type}})</p>
          <p v-if="dqcf.type=='OD'">右眼({{dqcf.type}})</p>
          <ul class="fcys">
            <li>球镜</li>
            <li>柱镜</li>
            <li>轴向</li>
          </ul>
          <ul class="fcys">
            <li>{{dqcf.ds}}</li>
            <li>{{dqcf.dc}}</li>
            <li>{{dqcf.x}}</li>
          </ul>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="showcf = false">确 定</el-button>
        </span>
      </el-dialog>

      <el-button type="primary" @click="dy">打印</el-button>
    </div>
</template>

<script>
import pf from "@/publicFn/baseFn"
    export default {
      name: "commodityEnd",
      data() {
        return {
          show: 2,
          shopdata: '',
          showcf: false,
          dqcf: {
            ds: '',
            dc: '',
            x: ''
          },
          dqcfarr: [],
          types: '',
          jftime: ''
        }
      },
      computed: {
         zj() {
           if (this.shopdata) {
             if (this.shopdata.ListCustomerOrderDetail && this.shopdata.ListCustomerOrderDetail.length) {
               let obj = this.shopdata.ListCustomerOrderDetail,str = 0
               for (let i = 0; i < obj.length; i++) {
                 str += obj[i].ProductRelation.ProductDetail.AllProduct.ProductOfficalPrice
               }
               return str
             }
           }
         }
      },
      created() {
        let orids = this.$store.state.bases.orid
        this.jftime = pf.getTime(0)
        pf.getUrlVal('orid')?orids = pf.getUrlVal('orid'):''
        if (!orids) {
          return this.$alert('订单ID错误，请稍后再试！', '提示')
        }
        this._api.publicApi.commodityJf(orids)
        .then(res => {
          if (res.GetResult) {
            this.shopdata = JSON.parse(JSON.stringify(res.GetResult))
            // console.log(this.shopdata)
            if (this.shopdata.ListCustomerOrderDetail) {
              let obj = this.shopdata.ListCustomerOrderDetail
              let arr = []
              for (let i = 0; i < obj.length; i++) {
                if (obj[i].CustomerOrderDetailData.Lens_DC || obj[i].CustomerOrderDetailData.Lens_DS || obj[i].CustomerOrderDetailData.Lens_X) {
                  arr.push({
                    dc: obj[i].CustomerOrderDetailData.Lens_DC,
                    ds: obj[i].CustomerOrderDetailData.Lens_DS,
                    x: obj[i].CustomerOrderDetailData.Lens_X,
                    type: obj[i].CustomerOrderDetailData.LeftOrRight
                  })
                }
              }
              this.dqcfarr = [...arr]
            }
          }
        })
      },

      methods: {
        xzcf(item) {
          this.showcf = true
          this.dqcf = JSON.parse(JSON.stringify(item))
        },
        tjs() {
          console.log(this.shopdata)
          if (this.shopdata.COServiceState == 'payed') {
            return  this._http.post('https://interface2.visumall.com/Customer/CustomerOrder.ashx?cmd=UpdateGiven', {
              "token": {
                "sToken": "E0B1486293C779EEE7CD24AF277703D7",
                "TimeSpan": "1574670974"
              },
              "o": {
                "COrderId": this.shopdata.COrderId,
                COServiceState: "given"
              }
            })
                .then(res => {
                  // console.log(res)
                  if (res.UpdateGivenResult == 1) {
                    this.$alert('提交成功', '提示')
                  }
                })
          }else if (this.shopdata.COServiceState == 'given') {
            return this.$alert('此订单已提交，请勿重复提交！', '提示')
          }else {
            return this.$alert('此订单不是已支付状态，不可交付', '提示')
          }

        },
        dy () {
          // let ifs = document.getElementById('dys')
          // this.$refs.print.style.scale = '0.7'
          if (this.types != 'rgp') {
            this.$print(this.$refs.print, {
              maxWidth: 1200,
              style: {
                /* 自定义样式 */
                scale: "0.05"
              }
            })
          }else {
            this.$print(this.$refs.print2, {
              maxWidth: 1200,
              style: {
                /* 自定义样式 */
                scale: "0.05"
              }
            })
          }

        }
      }
    }
</script>

<style scoped lang="scss">
    @media print {
      .dys {
        transform: scale(0.78); /* 调整缩放比例 */
        transform-origin: top left; /* 设置缩放原点 */
        width: 200%; /* 调整宽度以适应缩放 */
        overflow: hidden; /* 隐藏滚动条 */
      }
    }

    $b-top: 1px solid #5a5959;
    li {
        display: flex;
        align-items: center;
        justify-content: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .commodityEnd {
        width: 100vw;
        height: 76vh;
        //overflow: hidden;
        overflow: auto;
    }
    .top {
        padding-top: 10px;
    }
    .bg {
        width: 900px;
        margin: 30px auto;
        border: 1px solid #5a5959;
    }
    .bg-name {
        width: 100%;
        text-align: center;
        padding: 10px;
    }
    .gk-xx {
        display: flex;
        align-items: center;
        li{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            border-right: 1px solid #5a5959;
            border-top: 1px solid #5a5959;
        }
        li:nth-child(1) {width: 100px;}
        li:nth-child(2) {width: 200px;}
        li:nth-child(3) {width: 100px}
        li:nth-child(4) {width: 200px;}
        li:nth-child(5) {width: 100px;}
        li:nth-child(6) {width: 200px;border-right: none;}
    }
    .cfsj {
        display: flex;
        align-items: center;
        justify-content: start;
        border-top: $b-top;
        li {
            height: 30px;
            border-right: $b-top;
            width: 99px;
        }
    }
    .sp {
        display: flex;
        li {
            border-top: $b-top;
            border-right: $b-top;
            width: 82px;
            height: 25px;
        }
        li:nth-child(1) {width: 400px;}
        li:nth-child(7) {border-right: none;width: 84px}
    }
    .lc {
        display: flex;
        li {
            height: 30px;
            border-top: $b-top;
            border-right: $b-top;
        }
        li:nth-child(1) {width: 566px;}
        li:nth-child(2) {width: 333px;border-right: none;}
    }
    .d3bg {
        display: flex;
        li{
            border-top: $b-top;
            border-right: $b-top;
            height: 30px;
            width: 224px;
            p {width: 112px;height: 30px;line-height: 30px;}
            p:nth-child(1) {border-right: $b-top;}
        }
        li:nth-child(4) {border-right: none; width: 225px;}
    }
    .zysx {
        padding: 10px;
        border-top: $b-top;
        text-align: left;
    }
    .jfsj {
        display: flex;
        li{
            border-top: $b-top;
            border-right: $b-top;
            height: 30px;
        }
        li:nth-child(1) {width: 250px}
        li:nth-child(2) {width: 150px}
        li:nth-child(3) {width: 150px}
        li:nth-child(4) {width: 250px}
        li:nth-child(5) {width: 100px; border-right: none;}
    }


    .sphl {
        display: flex;
        li{
            border-top: $b-top;
            border-right: $b-top;
            height: 30px;
        }
        li:nth-child(1) {width: 200px;}
        li:nth-child(2) {width: 200px;}
        li:nth-child(3) {width: 200px;}
        li:nth-child(4) {width: 200px;}
        li:nth-child(5) {width: 100px;border-right: none;}
    }
    .jpjc {
        display: flex;
        li {
            border-top: $b-top;
            border-right: $b-top;
            height: 50px;
        }
        li:nth-child(1) {width: 100px;}
        li:nth-child(2) {width: 350px;}
        li:nth-child(3) {width: 100px;}
        li:nth-child(4) {width: 350px; border-right: none;}
    }
    .hlqj {
        display: flex;
        border-top: $b-top;
        li {
            height: 30px;
            border-right: $b-top;
        }
        li:nth-child(1) {width: 100px;}
        li:nth-child(2) {width: 800px;border-right: none;}
    }
    .sphl-last {
        display: flex;
        border-top: $b-top;
        li {
            height: 30px;
            border-right: $b-top;
        }
        li:nth-child(1) {width: 350px;}
        li:nth-child(2) {width: 350px;}
        li:nth-child(3) {width: 200px;border-right: none;}
    }

    .fcys {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      li {
        width: 100px;
        text-align: center;
      }
    }
</style>
